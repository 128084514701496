'use strict'

resizeFlg = {}
resizeFlg.pc = true
resizeFlg.sp = true
firstView = ''

pcPopupBanner = ()->
  $target = $('.p-popup-banner.js-headroom')

  $target.headroom({
    # offset: $(window).height()
  })

  $target.find('.js-close').on('click', ()->
    $target.addClass('is-close')
  )

spPopupBanner = ()->
  $target = $('.p-popup-banner.js-headroom')

  $target.headroom({
    # offset: $(window).height()
  })

  $(window).on('scroll', ()->
    # if ($(this).scrollTop() + $(window).height()) > $('.p-footer .p-footer__inquiry').offset().top + $('.p-footer .p-footer__inquiry').height()
    if ($(this).scrollTop() + $(window).height()) > $('.p-footer').offset().top
      $('.p-popup-banner.js-headroom').addClass('is-bottom')
    else
      $('.p-popup-banner.js-headroom').removeClass('is-bottom')
  )

  $target.find('.js-close').on('click', ()->
    $target.addClass('is-close')
  )

if window.matchMedia('(max-width:750px)').matches
  # SP
  firstView = 'SP'

  spPopupBanner()
else
  # PC
  firstView = 'PC'

  pcPopupBanner()

$(window).on('resize',
  ()->
    if window.matchMedia('(max-width:750px)').matches
      if firstView is 'PC' and resizeFlg.sp is true or
      firstView is false and resizeFlg.sp is true
        # PC から SP へ

        $('.p-popup-banner.js-headroom').headroom('destroy')

        spPopupBanner()

        resizeFlg.pc = true
        resizeFlg.sp = false
        firstView = false
    else
      if firstView is 'SP' and resizeFlg.pc is true or
      firstView is false and resizeFlg.pc is true
        # SP から PC へ

        $('.p-popup-banner.js-headroom').headroom('destroy')

        pcPopupBanner()

        resizeFlg.pc = false
        resizeFlg.sp = true
        firstView = false
)
